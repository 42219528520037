import { NewWindowIcon } from '@pm/icons';
import { TextLink } from '@pm/ui';
import { clsx } from 'clsx';
import Linkify from 'linkify-react';
import { ReactNode } from 'react';
import { Element } from 'react-markdown/lib';
import invariant from 'tiny-invariant';

const variantStyles: Record<string, string> = {
  default: 'text-content-default',
  subdued: 'text-content-subdued',
};

type returnType = {
  [key: string]: (
    props: { children?: ReactNode } & {
      node?: Element;
      href?: string;
    },
  ) => JSX.Element;
};

type Props = {
  variant: keyof typeof variantStyles;
};

export const useMarkdownComponents = ({
  variant = 'default',
}: Props): returnType => {
  return {
    p: ({ node, ...props }) =>
      typeof props.children === 'string' ? (
        <Linkify
          options={{
            target: '_blank',
            className:
              'text-content-link underline hover:text-content-link-hover',
          }}
        >
          <div
            className={clsx(variantStyles[variant], 'whitespace-pre-wrap')}
            {...props}
          />
        </Linkify>
      ) : (
        <div
          className={clsx(variantStyles[variant], 'whitespace-pre-wrap')}
          {...props}
        />
      ),
    ul: ({ node, ...props }) => (
      <ul
        {...props}
        className={clsx(variantStyles[variant], 'list-disc pl-6')}
      />
    ),
    a: ({ node, href, ...props }) => {
      invariant(
        href,
        'Href is required for anchor tags in questionnaire markdown descriptions',
      );
      // @note - https://github.com/purposemed/core/issues/8629 To be reverted after resources work
      return (
        <TextLink target="_blank" to={href} external>
          <div className="break-all inline-block">
            <NewWindowIcon className="h-5 w-5 inline-block mr-1" />
            {props.children}
          </div>
        </TextLink>
      );
    },
    em: ({ node, ...props }) => (
      <small {...props} style={{ fontSize: '0.75rem', lineHeight: '0rem' }} />
    ),
  };
};
