import { LDProvider } from 'launchdarkly-react-client-sdk';
import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { browserName, deviceType } from 'react-device-detect';
import { AuthContext } from '../context/auth/context';

type Props = {
  sdkKey: string;
  disabledWhenUnauthenticated?: boolean;
};

export const LaunchDarklyProvider: FunctionComponent<
  PropsWithChildren<Props>
> = ({ children, disabledWhenUnauthenticated = false, sdkKey }) => {
  const authContext = useContext(AuthContext);

  if (disabledWhenUnauthenticated && !authContext?.isLoggedIn) {
    return <>{children}</>;
  }

  const contextKey = authContext?.userId || undefined;

  const segmentUserId =
    localStorage.getItem('ajs_anonymous_id')?.replaceAll('"', '') ||
    localStorage.getItem('ajs_user_id')?.replaceAll('"', '') ||
    '';

  return (
    <LDProvider
      clientSideID={sdkKey}
      context={{
        kind: 'user',
        anonymous: !authContext?.isLoggedIn,
        key: contextKey,
        custom: {
          location: window.location.href,
          browserName: browserName,
          deviceType: deviceType,
          segmentId: segmentUserId,
        },
      }}
    >
      {children}
    </LDProvider>
  );
};
