import { cva, type VariantProps } from 'class-variance-authority';
import React, { ComponentProps, ElementType } from 'react';

const text = cva(undefined, {
  variants: {
    color: {
      contentDefault: 'text-content-default',
      contentSubdued: 'text-content-subdued',
      contentDisabled: 'text-content-disabled',
      contentInverse: 'text-content-inverse',
      contentPositive: 'text-content-positive',
      contentWarning: 'text-content-warning',
      contentNegative: 'text-content-negative',
      contentInfo: 'text-content-info',
      primary: 'text-primary',
    },
    size: {
      // Heading
      headingXL: 'text-heading-xl',
      headingL: 'text-heading-l',
      headingM: 'text-heading-m',
      headingS: 'text-heading-s',
      headingXS: 'text-heading-xs',
      // Body
      bodyS: 'text-body-s',
      bodyL: 'text-body-l',
      // Button
      buttonL: 'text-button-l',
      buttonS: 'text-button-s',
      // Label
      label: 'text-label',
      labelStrong: 'text-label-strong',
    },
    select: {
      none: 'select-none',
      text: 'select-text',
      all: 'select-all',
      auto: 'select-auto',
    },
    overflow: {
      default: '',
      truncate: 'truncate',
      ellipsis: 'text-ellipsis',
      clip: 'text-clip',
    },
  },
  defaultVariants: {
    size: 'bodyS',
    color: 'contentDefault',
    select: 'auto',
  },
});

type TextOwnProps<E extends ElementType = ElementType> = {
  children: React.ReactNode;
  as?: E;
};

type TextProps<E extends ElementType> = TextOwnProps<E> &
  Omit<ComponentProps<E>, keyof TextOwnProps> &
  VariantProps<typeof text> & {
    className?: string;
  };

const DEFAULT_ELEMENT = 'p';

export const Text = <E extends ElementType = typeof DEFAULT_ELEMENT>({
  size,
  color,
  select,
  overflow,
  children,
  className = '',
  as,
  ...props
}: TextProps<E>) => {
  const Component = as || DEFAULT_ELEMENT;

  return (
    <Component
      className={`${text({ size, color, select, overflow })} ${className}`.trim()}
      {...props}
    >
      {children}
    </Component>
  );
};
