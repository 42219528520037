import { ReactNode } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { AppFlags } from './app-flags';

type LDFlagSet = {
  [key: string]: unknown;
};

interface FlagProps<
  FlagSet,
  FlagKey extends keyof FlagSet,
  Match = FlagSet[FlagKey],
> {
  flag: FlagKey;
  defaultValue?: Match;
  when?: Match;
  children?: ReactNode;
  fallback?: ReactNode | null;
}

const usePurposemedFlags = () => useFlags<AppFlags>();

const useFlagMatch = <
  FlagSet extends LDFlagSet = AppFlags,
  Key extends keyof FlagSet = keyof FlagSet,
>({
  flag,
  defaultValue,
  when,
}: Pick<FlagProps<FlagSet, Key>, 'flag' | 'defaultValue' | 'when'>) => {
  const flags = useFlags<FlagSet>();
  const result = flags[flag] || defaultValue;

  if (typeof result === 'boolean') {
    if (when !== undefined) {
      return result === when;
    } else {
      return result;
    }
  }

  if (when !== undefined) {
    return result === when;
  }

  return false;
};

const Flag = <
  FlagSet extends LDFlagSet = AppFlags,
  Key extends keyof FlagSet = keyof FlagSet,
>({
  children,
  flag,
  when,
  defaultValue,
  fallback = null,
}: FlagProps<FlagSet, Key>) => {
  const isMatch = useFlagMatch({ flag, defaultValue, when });

  return <>{isMatch ? children : fallback}</>;
};

export { Flag, usePurposemedFlags, useFlagMatch };
