import * as RadioPrimitive from '@radix-ui/react-radio-group';
import { cva, type VariantProps } from 'class-variance-authority';
import { WithChildren } from '../../../types/component.types';

const radioGroupRoot = cva('grid', {
  variants: {
    space: {
      xxs: 'gap-xxs',
      s: 'gap-s',
      m: 'gap-m',
    },
    cols: {
      1: 'grid-cols-1',
      2: 'grid-cols-2',
      3: 'grid-cols-3',
    },
  },
  defaultVariants: {
    space: 's',
    cols: 1,
  },
});

type CombinedProps = WithChildren &
  VariantProps<typeof radioGroupRoot> &
  Omit<RadioPrimitive.RadioGroupProps, 'className'>;

export type RadioGroupProps = CombinedProps & {
  defaultValue?: string;
  onChange?: (value: string) => void;
};

export const RadioGroup = ({
  defaultValue,
  children,
  onChange,
  value,
  space = 's',
  cols = 1,
  ...primitiveProps
}: RadioGroupProps) => {
  if (!primitiveProps['aria-label'] && !primitiveProps['aria-labelledby']) {
    throw new Error(
      'RadioGroup requires an aria-label or aria-labelledby prop.',
    );
  }

  return (
    <RadioPrimitive.Root
      {...primitiveProps}
      value={value}
      className={radioGroupRoot({ space, cols })}
      defaultValue={defaultValue}
      onValueChange={(value) => onChange?.(value)}
    >
      {children}
    </RadioPrimitive.Root>
  );
};
