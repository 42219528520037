import { errorMessage, formField, supportingMessage } from '@pm/forms';
import {
  InputLabel,
  InteractiveCard,
  RadioField,
  RadioGroup,
  SingleSelect,
} from '@pm/ui';
import { ComponentProps, ElementType, useId } from 'react';
import { useController } from 'react-hook-form';

type Option =
  | ComponentProps<typeof RadioField>
  | ComponentProps<typeof SingleSelect>
  | ComponentProps<typeof InteractiveCard>;

type FormRadioGroupProps = Omit<
  ComponentProps<typeof RadioGroup>,
  'children'
> & {
  label?: React.ReactNode;
  name: string;
  options: Option[];
  supportingText?: string;
  freeTextDelimiter?: string;
  RadioComponent: ElementType;
};

const stripPrefixFromValue = ({
  prefix,
  value,
}: {
  prefix: string;
  value: string;
}) => {
  if (value.startsWith(prefix)) {
    return value.split(prefix)[1];
  }

  return '';
};

export const freeTextPrefixForOption = ({
  option,
  freeTextDelimiter,
}: {
  option: Option;
  freeTextDelimiter: string;
}) => `${option.value}${freeTextDelimiter}`;

/**
 * Component wrapper for radio group elements to be used with React Hook Forms.
 */
export const FormRadioGroup = ({
  label,
  name,
  options,
  supportingText,
  RadioComponent,
  space,
  cols,
  freeTextDelimiter = ': ',
  ...restProps
}: FormRadioGroupProps) => {
  const { field, fieldState } = useController({ name });
  const { error } = fieldState;
  const errorId = error ? `${name}-error` : undefined;
  const generatedLabelId = useId();
  const labelId = label ? generatedLabelId : undefined;

  const optionIsSelected = ({
    fieldValue,
    option,
  }: {
    fieldValue: string;
    option: Option;
  }) => {
    if (option.freeText) {
      return fieldValue.startsWith(
        freeTextPrefixForOption({ option, freeTextDelimiter }),
      );
    }

    return option.value === fieldValue;
  };

  return (
    <div className={formField({ space })}>
      {label && <InputLabel id={generatedLabelId}>{label}</InputLabel>}
      <RadioGroup
        space={space}
        cols={cols}
        aria-describedby={errorId}
        aria-label={restProps['aria-label']}
        aria-labelledby={labelId ?? restProps['aria-labelledby']}
      >
        {options.map((option) => {
          const freeTextPrefix = freeTextPrefixForOption({
            option,
            freeTextDelimiter,
          });

          return (
            <RadioComponent
              {...field}
              {...option}
              key={`${name}-${option.value}`}
              checked={optionIsSelected({
                fieldValue: field.value,
                option,
              })}
              onClick={() => {
                const updatedValue = option.freeText
                  ? freeTextPrefix
                  : option.value;
                field.onChange(updatedValue);
              }}
              freeText={
                option.freeText
                  ? {
                      ...option.freeText,
                      value: stripPrefixFromValue({
                        prefix: freeTextPrefix,
                        value: field.value,
                      }),
                      onChange: (
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        const updatedValue = field.value.startsWith(
                          freeTextPrefix,
                        )
                          ? `${freeTextPrefix}${event.target.value}`
                          : '';

                        field.onChange(updatedValue);
                      },
                    }
                  : undefined
              }
              ref={null}
            />
          );
        })}
      </RadioGroup>
      {error && (
        <p id={errorId} className={errorMessage()}>
          {error.message}
        </p>
      )}
      {supportingText && (
        <span className={supportingMessage()}>{supportingText}</span>
      )}
    </div>
  );
};
