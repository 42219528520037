import { useTranslation } from 'react-i18next';
import { HookFormSelect } from '../Select/HookFormSelect';
import { FormField } from '@pm/forms';
import { Input } from '@pm/ui';
import { useController } from 'react-hook-form';

type AppointmentTimeSlot = {
  time: Date;
  providerId?: string;
};

type TimePickerProps = {
  timeSlots: AppointmentTimeSlot[];
  timeFieldName: string;
  providerFieldName: string;
};

export const TimePicker = ({
  timeSlots,
  timeFieldName,
  providerFieldName,
}: TimePickerProps) => {
  const { t } = useTranslation('core', { keyPrefix: 'components' });
  const { field: providerField } = useController({ name: providerFieldName });

  const dateTimeOptions = timeSlots.map(({ time }) => ({
    text: t('timepicker.timeWithoutTimezone', { date: time }),
    value: time.toISOString(),
  }));

  return (
    <>
      <HookFormSelect
        name={timeFieldName}
        options={dateTimeOptions}
        cols={2}
        onSelect={(time: string) => {
          const providerId = timeSlots.find(
            (timeSlot) => timeSlot.time.toISOString() === time,
          )?.providerId;

          providerField.onChange(providerId);
        }}
      />
      <FormField
        type="hidden"
        name={providerFieldName}
        Component={Input}
        value={providerField.value ?? ''}
        aria-hidden
        hidden
        disabled
      />
    </>
  );
};
